import { useState, useEffect } from 'react';
import type { FormEvent, ChangeEvent } from 'react';
import { useHistory } from 'react-router-dom';
import i18n from 'i18next';

import { ApiCheckContry } from 'language/i18n';
import { Carousel, Modal, Spinner } from '@atoms/utils';
import { LoginForm } from '@organisms/forms';
import { CAROUSEL_MESSAGES, CAROUSEL_IMAGES, errorCodes } from '@constants';
import {
  useLoginMutation,
  useResendEmailAuthenticationMutation,
} from '@features/api';
import { useTypedDispatch } from '@features/rootStore';
import { updateAccessToken } from '@features/auth';
import { useModal } from '@hooks';
import * as S from './Login.style';

type LoginForm = {
  id: string;
  pw: string;
};
const initialLoginForm = {
  id: '',
  pw: '',
};

const Login = () => {
  const history = useHistory();
  const { isShown, modalContent, onModalOpen, onModalClose } = useModal();

  const [loginForm, setLoginForm] = useState<LoginForm>(initialLoginForm);
  const [isLoginError, setIsLoginError] = useState<boolean>(false);

  const dispatch = useTypedDispatch();
  const [login, loginResult] = useLoginMutation();
  const [resendEmailAuthentication, resendEmailAuthenticationResult] =
    useResendEmailAuthenticationMutation();

  const handleFormInputChange =
    (name: string) => (e: ChangeEvent<HTMLInputElement>) => {
      setLoginForm({
        ...loginForm,
        [name]: e.target.value,
      });
    };
  const handleEmailAuthenticationResend = async () => {
    await resendEmailAuthentication(loginForm);
    // 로딩 spinner가 있으면 좋을 듯!
    onModalOpen({
      message: '인증 메일이 발송되었습니다',
      subMessage: [
        '도착한 메일의 인증 버튼을 누르면 가입 절차가 완료됩니다.',
        '인증을 마친 후 인스타트렌드의 서비스를 이용해보세요!',
      ],
      firstButtonText: '확인',
    });
  };
  const handleConnectToKakaotalk = () => {
    onModalOpen({
      onFirstButtonClick: () => {
        window.open('http://pf.kakao.com/_pzfsb/chat');
      },
      message: '고객센터로 이동 됩니다',
      subMessage: ['카카오톡에 문의 사항을 남겨주세요.'],
      firstButtonText: '확인',
    });
  };
  const handleFormSubmit = (e: FormEvent<Element>) => {
    e.preventDefault();
    login(loginForm)
      .unwrap()
      .then(({ access_token }) => {
        dispatch(updateAccessToken(access_token));

        if (loginForm.id === `${process.env.REACT_APP_JP_ID}`) {
          i18n.changeLanguage('jp');
          localStorage.setItem('language', 'jp');
          localStorage.setItem('id', loginForm.id);
        }

        history.push('/startag');
      })
      .catch(({ data }) => {
        if (data && data.code === errorCodes.NEED_EMAIL_AUTENTICATION) {
          setIsLoginError(false);
          onModalOpen({
            onFirstButtonClick: handleEmailAuthenticationResend,
            message: '이메일 인증을 진행해주세요!',
            subMessage: [
              '서비스 이용 전 이메일 인증이 필요합니다.',
              '인증 메일 재전송이 필요할 경우 아래 버튼을 눌러주세요.',
            ],
            firstButtonText: '재전송',
          });
        } else if (data && data.code === errorCodes.UNACTIVE_USER) {
          setIsLoginError(false);
          onModalOpen({
            onFirstButtonClick: () => {
              window.open('http://pf.kakao.com/_pzfsb/chat');
            },
            message: '로그인할 수 없는 계정입니다.',
            subMessage: ['고객센터에 문의해주세요.'],
            firstButtonText: '문의하기',
          });
        } else {
          setIsLoginError(true);
        }
      });
  };

  const onCheckContry = async () => {
    const res = await ApiCheckContry();

    if (res && res === 'JP') {
      setTimeout(() => {
        login({
          id: `${process.env.REACT_APP_JP_ID}`,
          pw: `${process.env.REACT_APP_JP_PASSWORD}`,
        })
          .unwrap()
          .then(({ access_token }) => {
            dispatch(updateAccessToken(access_token));
            history.push('/startag');
          })
          .catch(({ data }) => {
            if (!data || !data.code) {
              return;
            }

            if (data.code === errorCodes.NEED_EMAIL_AUTENTICATION) {
              setIsLoginError(false);
              onModalOpen({
                onFirstButtonClick: handleEmailAuthenticationResend,
                message: '이메일 인증을 진행해주세요!',
                subMessage: [
                  '서비스 이용 전 이메일 인증이 필요합니다.',
                  '인증 메일 재전송이 필요할 경우 아래 버튼을 눌러주세요.',
                ],
                firstButtonText: '재전송',
              });
            } else if (data.code === errorCodes.UNACTIVE_USER) {
              setIsLoginError(false);
              onModalOpen({
                onFirstButtonClick: () => {
                  window.open('http://pf.kakao.com/_pzfsb/chat');
                },
                message: '로그인할 수 없는 계정입니다.',
                subMessage: ['고객센터에 문의해주세요.'],
                firstButtonText: '문의하기',
              });
            } else {
              setIsLoginError(true);
            }
          });
      }, 0);
    }
  };

  useEffect(() => {
    onCheckContry();
  }, []);

  if (loginResult.isLoading || resendEmailAuthenticationResult.isLoading) {
    return <Spinner />;
  }
  return (
    <S.Container>
      <S.Contents>
        <Carousel
          CarouselMessages={CAROUSEL_MESSAGES}
          CarouselImages={CAROUSEL_IMAGES}
        />
        <LoginForm
          isError={isLoginError}
          onSubmit={handleFormSubmit}
          onInputChange={handleFormInputChange}
          onConnectToKakaotalk={handleConnectToKakaotalk}
        />
      </S.Contents>
      <Modal
        isShown={isShown}
        modalContent={modalContent}
        onClose={onModalClose}
      />
    </S.Container>
  );
};
export default Login;
